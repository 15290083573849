import React from 'react'
import ReactFullpage from '@fullpage/react-fullpage'
import { Link } from 'gatsby'
import SEO from '../components/seo'
import Nav from '../components/nav'
import ExploreIcon from '../components/explore'
// import OutroVideo from '../videos/exchange106.mp4'

const Contact = () => {
  return (
    <>
      <SEO title="Contact" />
      <Nav logoFill="light">
        <div className="col text-left">
          <Link className="target fill-light" to="/explore" style={{height:"1.875rem"}}><ExploreIcon /></Link>
        </div>
      </Nav>
      <ReactFullpage
        // fullpage options
        licenseKey={'C1912978-A39F4CE4-9F6AC28B-DFB573DA'}
        sectionsColor={['#002856']}
        lazyLoading

        render={() => (
          <ReactFullpage.Wrapper>
            <section className="section" key="section0" id="section0">
              {/* <video className="video" loop muted data-autoplay>
                <source src={OutroVideo} type="video/mp4" />
              </video> */}
              <div className="container overlay bg-dark col-md-6 offset-md-3 p-5">
                <h2 className="title">
                  Contact us
                </h2>
                <div className="pt-3">
                  <form action="https://formspree.io/xnqdrjrn" method="POST">
                    <div className="form-group">
                      <label className="control-label mr-auto" htmlFor="name">Name</label>
                      <input className="form-control" aria-label="Name" type="text" name="name" id="name" placeholder="" />
                    </div>

                    <div className="form-group">
                      <label htmlFor="email" className="control-label mr-auto">Email</label>
                      <input type="email" aria-label="Email" name="_replyto" id="email" className="form-control" placeholder="" />
                    </div>

                    <div className="form-group">
                      <label htmlFor="message">Message</label>
                      <textarea name="message" aria-label="Message" id="message" className="form-control" rows="6" placeholder=""></textarea>
                    </div>

                    <div className="form-group mb-0 pt-3">
                      <input type="hidden" aria-label="Subject" name="_subject" value="Enquiry from Exchange.my" />
                      <input type="hidden" aria-label="Cc" name="_cc" value="info@exchange106.my,tongjiahuan@gmail.com" />
                      <input type="hidden" aria-label="Next" name="_next" value="contact.html" />
                      <button type="submit" className="btn btn-primary btn-block btn-lg" value="Send">Send</button>
                    </div>
                  </form>
                </div>
              </div>
            </section>
          </ReactFullpage.Wrapper>
        )}
      />
    </>
  )
}

export default Contact